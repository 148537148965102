<template>
    <el-row>
        <!-- 主题 -->
        <el-col :span="12">
            <div>主题</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.theme}}</div>
        </el-col>
        <!-- 风格 -->
        <el-col :span="12">
            <div>风格</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.style}}</div>
        </el-col>
        <!-- 摄影师 -->
        <el-col :span="12">
            <div>摄影师</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.lensman}}{{order_detail.lensman_phone}}</div>
        </el-col>
        <!-- 化妆师 -->
        <el-col :span="12">
            <div>化妆师</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.dresser}}{{order_detail.dresserPhone}}</div>
        </el-col>
        <!-- 胶卷 -->
        <el-col :span="12">
            <div>胶卷</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.film}}</div>
        </el-col>
        <!-- 场地 -->
        <el-col :span="12">
            <div>场地</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{order_detail.space}}</div>
        </el-col>
        <!-- 人数 -->
        <el-col :span="12">
            <div>人数</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">
                <el-input-number
                    :value="order_detail.photography_number"
                    size="mini"
                    disabled>
                </el-input-number>
            </div>
        </el-col>
        <!-- 拍摄费用 -->
        <el-col :span="12">
            <div>拍摄费用</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{"¥"+order_detail.photography_price}}</div>
        </el-col>
        <!-- 化妆费用 -->
        <el-col :span="12">
            <div>化妆费用</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{"¥"+order_detail.dresser_price}}</div>
        </el-col>
        <!-- 场地费用 -->
        <el-col :span="12">
            <div>场地费用</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{"¥"+order_detail.space_price}}</div>
        </el-col>
        <!-- 加人费用 -->
        <el-col :span="12">
            <div>加人费用</div>
        </el-col>
        <el-col :span="12">
            <div class="photograph_check_order_detail_rt">{{"¥"+order_detail.over_member}}</div>
        </el-col>
    </el-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    computed: mapState({
        order_detail: state => state.order.order_detail,
		
    }),
	mounted() {
		console.log(this.order_detail)
	},
}
</script>

<style scoped>
/* 订单详情右侧内容 */
.photograph_check_order_detail_rt{
    text-align: right;
}
</style>
